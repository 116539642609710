import React, { useRef, useState } from "react";
import "./Slider.css";
import { Link } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';


function Slider({freePosts, premiumPosts}){
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
      progressCircle.current.style.setProperty('--progress', 1 - progress);
      progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };


    return(
        <>
        <div className="height-200"></div>
        <div className="flex flex-row space-between mobile-control">

            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                delay: 4500,
                disableOnInteraction: false,
                }}
                pagination={false}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="swiper-lg"
            >
                {premiumPosts.map((post) => (
                        <SwiperSlide key={post.id}>
                            <div className="swiper-content">
                                <img src={post.banner} alt={post.title} className="swiper-image"></img>
                                <div className="swiper-img-filter"></div>
                                <h2 className="swiper-title">{post.title}</h2>
                                <Link to={`/post/${post.slug}`} className="flex justify-content-center align-center post-btn btn-banner-active swiper-btn" >مشاهده بیشتر&nbsp;<span className="material-symbols-outlined">arrow_circle_left</span></Link>
                            </div>
                        </SwiperSlide>
                ))}
                
            </Swiper>



            
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                delay: 3500,
                disableOnInteraction: false,
                }}
                pagination={{
                clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="swiper-sm"
            >
                {freePosts.map((post) => (
                    <SwiperSlide key={post.id}>
                        <div className="swiper-content">
                            <img src={post.banner} alt={post.title} className="swiper-image"></img>
                            <div className="swiper-img-filter"></div>
                            <h3 className="swiper-title">{post.title}</h3>
                            <Link to={`/post/${post.slug}`} className="flex justify-content-center align-center post-btn btn-banner-active swiper-btn" >مشاهده بیشتر&nbsp;<span className="material-symbols-outlined">arrow_circle_left</span></Link>
                        </div>
                    </SwiperSlide>
                ))}

                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>
            </Swiper>
        </div>






            <div className="flex flex-row slider-box align-center space-around just-slider">
                <div className="flex padding-15">
                    <img src={process.env.PUBLIC_URL + "/static/images/banner/vangogh.webp"} alt="Banner" className="banner-img img-responsive rounded-xs"></img>
                </div>

                <div className="flex flex-column">
                    <h1 className="header-text-banner padding-15 mobile-center text-center-mobile">
                        روی خودت سرمایه گذاری کن!
                    </h1>
                    <h4 className="text-banner padding-15 text-justify-mobile padding-25-mobile">
                        با کمترین ریسک و میزان سرمایه، در بلند مدت و کوتاه مدت از بازار های مالی و کریپتو بیشترین بهره رو ببرید.
                    </h4>

                    <div className="flex flex-row mobile-control">
                        <Link to="/dashboard" className="btn-banner-active">شروع استفاده از پکیج ها</Link>
                    </div>
                </div>

            </div>


        </>
    );
}

export default Slider;